.fa-map-marker{
    color:rgb(186, 85, 85);
    margin-right: 10px;
}
.fa-phone{
    color: rgb(49, 47, 47);
    margin-right: 10px;
}
.fa-taxi{
    margin-right: 10px;
}
.fa-train{
    margin-right: 10px;
}
.text-end{
    text-align: end;
}
