.fa-facebook-f{
    color: rgb(220, 216, 245);
    /* padding:20px; */
}
/* .fa-instagram{
    color:rgb(224, 34, 154)
} */
.fa-youtube{
    color: red;
    /* padding:20px */
}
/* .fa-linkedin-in{
    color:white;
} */
.fa-twitter{
    color: rgb(20, 69, 115);

}